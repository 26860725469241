<template>
    <div>
        <div v-if="!entry" class="l-padded">
            <p>{{ $t('assetDoesNotExist') }}</p>
        </div>

        <template v-else>
            <div class="u-bb">
                <AssetDetailOverview
                    :tracker="entryDetailed || entry"
                    :show-actions="true"
                />
            </div>

            <div
                v-if="
                    entry.location_details ||
                        (entry.asset_details &&
                            entry.asset_details.position.latitude &&
                            entry.asset_details.position.longitude)
                "
                class="u-bb"
            >
                <div class="l-padded u-bb">{{ $t('positionTitle') }}</div>

                <router-link
                    v-for="loc in currentLocations"
                    :key="loc.id"
                    :to="{
                        name: 'location',
                        params: { id: loc.id },
                    }"
                >
                    <ListItem class="l-center">
                        <div class="l-inline l-gap-2 l-center l-grow-1">
                            <PinLocationIcon
                                :name="$t('currentLocation')"
                                width="24"
                                height="24"
                            />
                            <div class="l-stack l-grow-1">
                                <h3>{{ loc.name }}</h3>
                                <TimeAgo
                                    v-if="lastGpsPositionTimestamp"
                                    :from-datetime="lastGpsPositionTimestamp"
                                    class="item-info"
                                />
                            </div>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="entry.location_details"
                    :to="{
                        name: 'location',
                        params: { id: entry.location_details.id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <PinHomeIcon
                                :name="$t('homeLocation')"
                                width="24"
                                height="24"
                            />
                            <h3>
                                {{
                                    entry.location_details &&
                                        entry.location_details.name
                                }}
                            </h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <a v-if="googleMapsLink" :href="googleMapsLink" target="_blank">
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <OpenInNewIcon width="24" height="24" />
                            <h3 class="t-underline">{{ $t('showOnMap') }}</h3>
                        </div>
                    </ListItem>
                </a>

                <router-link
                    v-if="
                        isSuperuser &&
                            entryDetailed &&
                            entryDetailed.asset_details.schema_url
                    "
                    :to="{ name: 'assetScheme' }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <GridRulerIcon width="24" height="24" />
                            <h3>{{ $t('showScheme') }}</h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="
                        supportsTrips !== undefined &&
                            entry.last_gps_measurement &&
                            entry.last_gps_measurement.lat &&
                            entry.last_gps_measurement.lng
                    "
                    :to="{
                        name: supportsTrips ? 'triphistory' : 'locationhistory',
                        params: {
                            id,
                            isTripModePossible: supportsTrips !== undefined,
                        },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <ClockIcon width="24" height="24" />
                            <h3>
                                {{
                                    supportsTrips
                                        ? $t('triphistory')
                                        : $t('history')
                                }}
                            </h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="isLocationHistoryChartAvailable"
                    :to="{ name: 'assetLocationHistoryChart' }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <TimeClockFileSearch width="24" height="24" />
                            <h3>
                                {{ $t('router.assetLocationHistoryChart') }}
                            </h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="isBeaconHistoryChartAvailable"
                    :to="{ name: 'assetBeaconHistoryChart' }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <BeaconBluetooth width="24" height="24" />
                            <h3>
                                {{ $t('router.assetBeaconHistoryChart') }}
                            </h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="isGatewayHistoryChartAvailable"
                    :to="{ name: 'assetGatewayHistoryChart' }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <BeaconBluetooth width="24" height="24" />
                            <h3>
                                {{ $t('router.assetGatewayHistoryChart') }}
                            </h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>

                <ListItem v-if="!entry.asset_details.static" class="l-center-v">
                    <div>{{ $t('followTrackerUpdates') }}</div>

                    <ToggleButton
                        :value="shouldFollowActiveTrackerUpdates"
                        @input="setShouldFollowActiveTrackerUpdates"
                    />
                </ListItem>
            </div>

            <div v-if="isMeasurementsAvailable" class="u-bb">
                <div class="l-padded u-bb">{{ $t('measurements') }}</div>

                <MeasurementsList :tracker="entry" />
            </div>

            <div v-if="additionalData.length" class="u-bb">
                <div class="l-padded u-bb">{{ $t('customerFields') }}</div>

                <div class="l-padded l-stack l-gap-1">
                    <div
                        v-for="item in additionalData"
                        :key="item.key"
                        class="l-inline l-center-v l-gap-1"
                    >
                        <b class="no-breaks">{{ item.label }}</b>

                        <input
                            v-if="item.type === 'boolean'"
                            type="checkbox"
                            :checked="item.value"
                            class="no-pointer-events"
                        />

                        <a
                            v-else-if="item.format === 'uri'"
                            :href="item.value"
                            target="_blank"
                            class="t-truncated t-underline"
                        >
                            {{ item.value }}
                        </a>

                        <span v-else>{{ item.value }}</span>
                    </div>
                </div>
            </div>

            <DownlinksList :id="id" class="u-bb" />

            <AssetNotifications
                v-if="hasRulesAccess"
                :asset="entry.asset"
                class="u-bb"
            />

            <HiddenForm
                v-if="entryDetailed && entryDetailed.config"
                class="u-bb"
                :title-class="{ 'u-bb': !isConfigListHidden }"
                :hidden="isConfigListHidden"
                @toggle="isConfigListHidden = $event"
            >
                <template #title>
                    {{ $t('configuration') }}
                </template>

                <template #form>
                    <AssetConfigList :asset="entryDetailed" />
                </template>
            </HiddenForm>

            <AssetDocuments
                :asset="entry.asset"
                class="u-bb"
                :show-edit-actions="hasEditRights"
            />

            <AssetMaintenancePolicies
                v-if="hasMaintenanceAccess"
                :asset="entry.asset"
                class="u-bb"
            />

            <div v-if="hasDebugAccess" class="u-bb">
                <div class="l-padded u-bb">{{ $t('diagnostics') }}</div>

                <div v-if="isBluetoothDevice">
                    <div @click="isGatewayListVisible = !isGatewayListVisible">
                        <ListItem>
                            <div class="l-inline l-center-v l-gap-2">
                                <GatewayIcon width="24" height="24" />
                                <h3>
                                    {{ $t('bluetoothGateways') }} ({{
                                        gatewayList ? gatewayList.length : 0
                                    }})
                                </h3>
                            </div>

                            <ArrowDownIcon
                                width="12"
                                height="12"
                                class="arrow-icon"
                                :class="{
                                    'arrow-icon--rotated': isGatewayListVisible,
                                }"
                            />
                        </ListItem>
                    </div>

                    <div
                        v-if="isGatewayListVisible"
                        class="l-padded table-gateway"
                    >
                        <span>RSSI</span>
                        <div></div>
                        <div></div>
                        <template v-for="(item, i) in gatewayList">
                            <span :key="i * 10 + 1">{{ item.rssi }}</span>
                            <div :key="i * 10 + 2" class="l-stack">
                                <h3>{{ item.gateway.name }}</h3>
                                <p>
                                    <strong>{{ item.gateway.deveui }}</strong>
                                </p>
                                <p>
                                    {{ formatTime(item.timestamp) }}
                                </p>
                                <TimeAgo :from-datetime="item.timestamp" />
                            </div>
                            <a
                                :key="i * 10 + 3"
                                :href="'#/map/assets/' + item.gateway.id"
                                target="_blank"
                            >
                                <OpenInNewIcon></OpenInNewIcon>
                            </a>
                        </template>
                    </div>
                </div>
                <div v-if="isSuperuser">
                    <router-link
                        :to="{
                            name: 'assetConnectionHeatmap',
                            params: { assetId: id },
                        }"
                    >
                        <ListItem>
                            <div class="l-inline l-center-v l-gap-2">
                                <HeatmapIcon width="24" height="24" />
                                <h3>{{ $t('connectionHeatmap') }}</h3>
                            </div>

                            <p><i class="arrow right" /></p>
                        </ListItem>
                    </router-link>
                </div>

                <router-link
                    :to="{
                        name: 'networkChart',
                        params: { assetId: id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-center-v l-gap-2">
                            <NetworkSignalIcon width="24" height="24" />
                            <h3>{{ $t('signalQuality') }}</h3>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>
            </div>
        </template>

        <transition name="slide-from-right">
            <div v-if="isDetachedPanelActive" class="detached-panel">
                <div
                    class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                >
                    {{ detachedPanelTitle }}

                    <router-link :to="{ name: 'detail', params: { id } }">
                        <IconButton @click="$emit('expand')">
                            <RemoveIcon width="24" height="24" color="black" />
                        </IconButton>
                    </router-link>
                </div>

                <div class="detached-panel-body">
                    <router-view />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment-timezone'

import { httpHelper, languageHelper } from '@/utils'
import ArrowDownIcon from '../icons/ArrowDownIcon'
import AssetConfigList from '../AssetConfigList'
import AssetDetailOverview from '../AssetDetailOverview'
import AssetDocuments from '../AssetDocuments'
import AssetMaintenancePolicies from '../AssetMaintenancePolicies'
import AssetNotifications from '../AssetNotifications'
import BeaconBluetooth from '../icons/BeaconBluetooth'
import ClockIcon from '../icons/HistoryClockIcon'
import DownlinksList from '../DownlinksList'
import GatewayIcon from '../icons/GatewayIcon'
import GridRulerIcon from '../icons/GridRulerIcon'
import HeatmapIcon from '../icons/HeatmapIcon'
import HiddenForm from '../HiddenForm'
import IconButton from '../IconButton'
import ListItem from '../ListItem'
import MeasurementsList from '../MeasurementsList'
import NetworkSignalIcon from '../icons/NetworkSignalIcon'
import OpenInNewIcon from '../icons/OpenInNewIcon'
import PinHomeIcon from '../icons/PinHomeIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import RemoveIcon from '../icons/RemoveIcon'
import TimeAgo from '../TimeAgo'
import TimeClockFileSearch from '../icons/TimeClockFileSearch'
import ToggleButton from '../ToggleButton'

export default {
    name: 'AssetDetailView',
    components: {
        ArrowDownIcon,
        AssetConfigList,
        AssetDetailOverview,
        AssetDocuments,
        AssetMaintenancePolicies,
        AssetNotifications,
        BeaconBluetooth,
        ClockIcon,
        DownlinksList,
        GatewayIcon,
        GridRulerIcon,
        HeatmapIcon,
        HiddenForm,
        IconButton,
        ListItem,
        MeasurementsList,
        NetworkSignalIcon,
        OpenInNewIcon,
        PinHomeIcon,
        PinLocationIcon,
        RemoveIcon,
        TimeAgo,
        TimeClockFileSearch,
        ToggleButton,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            entryDetailed: null,
            gatewayList: null,
            isConfigListHidden: true,
            isGatewayListVisible: false,
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
        ...mapState('location', ['locations']),
        ...mapState('tracker', [
            'assetTypes',
            'shouldFollowActiveTrackerUpdates',
            'trackers',
        ]),
        ...mapGetters('auth', [
            'hasDebugAccess',
            'hasLocationHistoryAccess',
            'hasMaintenanceAccess',
            'hasRulesAccess',
            'isSuperuser',
        ]),
        ...mapGetters('tracker', ['assetTypesById', 'assetsById']),
        additionalData() {
            const schema = this.assetTypesById[
                this.entry.asset_details.asset_type
            ]?.additional_data_schema.properties

            return Object.keys(schema ?? {})
                .filter(key => schema[key].display !== false)
                .map(key => {
                    const value =
                        this.entry.asset_details.additional_data?.[key] ??
                        schema[key].default

                    return {
                        key,
                        type: schema[key].type,
                        format: schema[key].format,
                        label:
                            schema[key].label?.[this.$i18n.locale] ||
                            schema[key].label?.[
                                languageHelper.defaultLanguage
                            ] ||
                            key,
                        value:
                            schema[key].format === 'date'
                                ? moment(value).format('DD.MM.YYYY')
                                : value,
                    }
                })
        },
        currentLocations() {
            return this.entryDetailed?.asset_details?.location_history_enabled
                ? this.entry.asset_details.current_locations
                      .map(id => this.locations.find(item => item.id === id))
                      .filter(Boolean)
                : []
        },
        detachedPanelTitle() {
            if (this.$route.name === 'chartsMeasurements') {
                let title, titleMain
                const options = this.assetTypesById[
                    this.assetsById[this.id]?.asset_details.asset_type
                ]?.measurements?.items?.[this.$route.params.measurement]
                options?.chart?.forEach(item => {
                    const props = item.main
                        ? { ...options.props, ...item.props }
                        : item.props
                    const measurements = item.main
                        ? options.measurements
                        : item.measurements
                    const itemTitle =
                        props?.label?.[this.$i18n.locale] ||
                        props?.label?.[languageHelper.defaultLanguage] ||
                        (measurements?.length === 1 ? item.measurements[0] : '')
                    if (itemTitle) {
                        if (item.main) {
                            titleMain = itemTitle
                        }
                        title = title ? `${title} & ${itemTitle}` : itemTitle
                    }
                })
                return titleMain || title || this.$t('router.charts')
            } else if (
                this.$route.name === 'chartsCombined' &&
                this.$route.query.measurement?.length
            ) {
                const dataTypes = [].concat(this.$route.query.measurement)

                if (
                    this.entry?.asset_details.asset_type_type === 'salt' &&
                    dataTypes.every(type => /^mass_*/.test(type))
                ) {
                    return this.$t('router.saltVehicleMassCharts')
                }

                if (
                    this.entry?.asset_details.asset_type_type ===
                        'cluey-leakage' &&
                    dataTypes.every(type => /^ai\d+$/.test(type))
                ) {
                    return this.$t('router.clueyLeakageSensors')
                }

                if (
                    this.entry?.asset_details.asset_type_type ===
                        'fence-guard' &&
                    dataTypes.every(type => /^voltage_\d+$/.test(type))
                ) {
                    return this.$t('router.fenceGuardVoltage')
                }

                if (dataTypes.every(type => /^t\d+$/.test(type))) {
                    return this.$t('router.temperature')
                }

                return dataTypes
                    .map(item => {
                        const key = `router.${item}`
                        return this.$root.$te(key) ? this.$t(key) : item
                    })
                    .join(' & ')
            }

            let prefix = ''

            switch (this.entry?.asset_details.asset_type_type) {
                case 'dragino-22222':
                    prefix = 'dragino_'
                    break
                case 'ecocoach-power-trolley':
                    prefix = 'ecocoach_'
                    break
                case 'fency-boy':
                    prefix = 'fencyBoy_'
                    break
                case 'road-maintenance':
                    prefix = 'roadMaintenance_'
                    break
            }

            const routeName =
                this.$route.name === 'charts'
                    ? this.$route.params.dataType
                    : this.$route.name

            return this.$root.$te(`router.${prefix}${routeName}`)
                ? this.$t(`router.${prefix}${routeName}`)
                : this.$root.$te(`router.${routeName}`)
                ? this.$t(`router.${routeName}`)
                : routeName
        },
        entry() {
            return this.trackers.find(entry => entry.id == this.id)
        },
        googleMapsLink() {
            return process.env.VUE_APP_ENVIRONMENT_NAME !== 'orkanet' &&
                this.entry.asset_details?.position?.latitude &&
                this.entry.asset_details?.position?.longitude
                ? 'http://www.google.com/maps/place/' +
                      this.entry.asset_details.position.latitude +
                      ',' +
                      this.entry.asset_details.position.longitude
                : null
        },
        hasEditRights() {
            return this.entry?.asset_details.permissions?.access === 'WRITE'
        },
        isBeaconHistoryChartAvailable() {
            return this.entry?.network === 'bluetooth'
        },
        isBluetoothDevice() {
            return this.entryDetailed?.network === 'bluetooth'
        },
        isGatewayHistoryChartAvailable() {
            return this.entry?.is_ble_gateway
        },
        isDetachedPanelActive() {
            return (
                this.$route.meta?.isChart || this.$route.name === 'assetScheme'
            )
        },
        isLocationHistoryChartAvailable() {
            return (
                this.hasLocationHistoryAccess &&
                this.entryDetailed?.asset_details.location_history_enabled
            )
        },
        isMeasurementsAvailable() {
            return (
                this.entry?.asset_details?.sensor_data &&
                Object.keys(this.entry.asset_details.sensor_data).length
            )
        },
        lastGpsPositionTimestamp() {
            return this.entryDetailed?.last_gps_measurement?.timestamp
        },
        supportsTrips() {
            return this.entryDetailed?.asset_details?.supports_trips
        },
    },
    watch: {
        entry() {
            this.setActiveTrackerOnMap(this.entry)

            if (this.entryDetailed) {
                this.entryDetailed.asset_details.sensor_data = this.entry.asset_details.sensor_data
                this.entryDetailed.last_message_timestamp = this.entry.last_message_timestamp
                this.entryDetailed.last_gps_measurement = this.entry.last_gps_measurement
            }
        },
        id() {
            this.fetchAssetDetails()
        },
    },
    async mounted() {
        if (!this.entry) {
            return
        }

        this.setActiveTrackerOnMap(this.entry)
        this.setShouldFollowActiveTrackerUpdates(true)
        await this.fetchAssetDetails()
        await this.fetchGateways()
    },
    beforeRouteLeave(to, from, next) {
        if (to.name !== 'editAsset' && to.params.id !== this.entry?.asset) {
            this.setActiveTrackerOnMap(null)
        }

        next()
    },
    methods: {
        ...mapMutations('tracker', [
            'setActiveTrackerOnMap',
            'setShouldFollowActiveTrackerUpdates',
        ]),
        async fetchAssetDetails() {
            this.entryDetailed = null
            const { data } = await httpHelper.get(`trackers/${this.id}/`)
            this.entryDetailed = data
        },
        async fetchGateways() {
            this.gatewayList = null
            if (this.isBluetoothDevice) {
                const { data } = await httpHelper.get(
                    `/ble/asset/${this.entryDetailed.asset}/gws/`
                )
                this.gatewayList = data
            }
        },
        formatTime(time) {
            return moment(time).format('DD.MM.YYYY HH:mm:ss')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "assetDoesNotExist": "This asset does not exist.",
        "bluetoothGateways": "Bluetooth Gateways",
        "configuration": "Configuration",
        "connectionHeatmap": "Connection Heatmap",
        "currentLocation": "Current location",
        "customerFields": "Customer defined fields",
        "diagnostics": "Diagnostics",
        "documents": "Documents",
        "followTrackerUpdates": "Follow position updates",
        "history": "History",
        "homeLocation": "Home location",
        "measurements": "Measurements",
        "notifications": "Notifications",
        "positionTitle": "Position information",
        "settings": "Settings",
        "showOnMap": "Show on Google Maps",
        "showScheme": "Show Schema",
        "signalQuality": "Signal Quality",
        "tracking": "Tracking",
        "triphistory": "Trip History"
    },
    "de": {
        "assetDoesNotExist": "Dieses Asset existiert nicht.",
        "bluetoothGateways": "Bluetooth Gateways",
        "configuration": "Konfiguration",
        "connectionHeatmap": "Verbindungsqualität",
        "currentLocation": "Aktueller Standort",
        "customerFields": "Benutzerdefinierte Felder",
        "diagnostics": "Diagnose",
        "documents": "Dokumente",
        "followTrackerUpdates": "Live Position verfolgen",
        "history": "Verlauf",
        "homeLocation": "Home Standort",
        "measurements": "Messungen",
        "notifications": "Benachrichtigungen",
        "positionTitle": "Positionsinformationen",
        "settings": "Einstellungen",
        "showOnMap": "Auf Google Maps anzeigen",
        "showScheme": "Schema anzeigen",
        "signalQuality": "Signal Qualität",
        "tracking": "Tracking",
        "triphistory": "Fahrtenverlauf"
    },
    "fr": {
        "assetDoesNotExist": "Cet asset n'existe pas.",
        "bluetoothGateways": "Bluetooth Gateways",
        "configuration": "Configuration",
        "connectionHeatmap": "Qualité de la connexion",
        "currentLocation": "Emplacement actuel",
        "customerFields": "Champs définis par le client",
        "diagnostics": "Diognostic",
        "documents": "Documents",
        "followTrackerUpdates": "Suivre la mise à jour de la position",
        "history": "Historique",
        "homeLocation": "Home location",
        "measurements": "Mesures",
        "notifications": "Notifications",
        "positionTitle": "Information de la position",
        "settings": "Paramètres",
        "showOnMap": "Afficher sur Google Maps",
        "showScheme": "Afficher le schéma",
        "signalQuality": "Qualité du signal",
        "tracking": "Tracking",
        "triphistory": "Parcours"
    },
    "it": {
        "assetDoesNotExist": "Questo asset non esiste.",
        "bluetoothGateways": "Bluetooth Gateways",
        "configuration": "Configurazione",
        "connectionHeatmap": "Qualità della connessione",
        "currentLocation": "Locazione attuale",
        "customerFields": "Campi definiti dal cliente",
        "diagnostics": "Diagnostica",
        "documents": "Documenti",
        "followTrackerUpdates": "Seguire la posizione",
        "history": "Storico",
        "homeLocation": "Home location",
        "measurements": "Misure",
        "notifications": "Notificazione",
        "positionTitle": "Informazioni sulla posizione",
        "settings": "Impostazione",
        "showOnMap": "Vedere su Google Maps",
        "showScheme": "Mostra schema",
        "signalQuality": "Qualità del segnale",
        "tracking": "Tracking",
        "triphistory": "Percorso di viaggio"
    }
}
</i18n>

<style lang="scss" scoped>
.table-gateway {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-gap: 6px 10px;
    padding: 16px;
    align-items: center;
}

.arrow-icon {
    margin: 0 4px;
    transition: transform 0.2s;

    &--rotated {
        transform: rotate(0.5turn);
    }
}

p {
    font-size: $fs-small;
}

.list-item__action {
    cursor: pointer;
}

.last_gps_measurement-icon {
    background-color: orange;
}

.item-info {
    margin-top: 3px;
    color: #808080;
}

.no-breaks {
    white-space: nowrap;
}

.no-pointer-events {
    pointer-events: none;
}
</style>
